const { initializeApp } =require("firebase/app");
// import { getAnalytics } from "firebase/analytics";
const { getMessaging, getToken, onMessage } =require("firebase/messaging");

const firebaseConfig = {
    apiKey: "AIzaSyBpRVTwubg0kXuiOWn-J9JSAvWLIOhub-0",
    authDomain: "fir-notifications-2da00.firebaseapp.com",
    projectId: "fir-notifications-2da00",
    storageBucket: "fir-notifications-2da00.appspot.com",
    messagingSenderId: "277575094953",
    appId: "1:277575094953:web:90ced7fe81d8a5d7f2cfb8",
    measurementId: "G-CY9FSM528Z"
  };

  const vapidKey="BLsSR7ezRUJpyDS_ZvX0AxiLDTxMuBoBjrlegpFmEL_s0kmuMh42jsiRpo-Jk3JEksk_RtyuTTAZgFTNZ-mr7K0";

  const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging=getMessaging(app)

export const requestFCMToken=async()=>{
    return Notification.requestPermission().then((permission)=>{
        if(permission==="granted"){
            return getToken(messaging,{vapidKey:vapidKey})
        }else{
            throw new Error("Notification not granted")
        }
    }).catch((err)=>{
        console.log("Error geting FCM Token: ",err);
        throw err
    })
}

export const onMessageListner=()=>{
  return new Promise((resolve)=>{
        onMessage(messaging,(payload)=>{
            resolve(payload);
        })
    })
}